import { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import events from '../../data/events.json';
import profiles from '../../data/profiles.json';
import { media } from '../../style/breakpoints';
import { colorStack } from '../../style/colorStack';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
`;

const SingleEvent = styled.div`
    background: rgba(255,255,255, .3);
    color: ${colorStack.white};
    padding: 1rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    border-radius: 8px;
    ${media.desktop`
        background: rgba(0,0,0, .5);
        flex-direction: row;
    `}
    img {
        width: 250px;
        margin-right: 2rem;
    }
    strong {
        font-size: 2rem;
    }
    > div {
        margin-bottom: 1rem;
    }
`;

const Description = styled.p`
    margin: 1rem 0;
`;

const Profile = styled(Link)`
    cursor: pointer;
    display: inline-block;
    img {
        border-radius: 100%;
        width: 50px;
        margin: -5px;
    }
`;

export const Events: FC = () => {
    return (
        <Wrapper>
            {events.map(elem => (
                <SingleEvent>
                    <div>
                        <img src={elem.logo} alt="Event logo" />
                    </div>
                    <div>
                        <span><strong>{elem.eventName}</strong></span>
                        <Description>{elem.description}</Description>
                        {elem.team.map(person => (
                            <Profile to={`/profile/${person}`}><img src={`${process.env.PUBLIC_URL}/img/profiles/${person}/avatar.png`} alt={person} /></Profile>
                        ))}
                    </div>
                </SingleEvent>
            ))}
        </Wrapper>
    );
};