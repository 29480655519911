import { FC } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import styled from 'styled-components';

import { media } from '../../style/breakpoints';
import { TopMenu } from './topMenu';

const Wrapper = styled.div`
    width: 100%;
    ${media.desktop`
        height: 100vh;
        background-image: url(${`${process.env.PUBLIC_URL}/img/fubar-airsoft-team.jpg`});
        background-size: cover;
        background-position: 0px 90px;
        background-repeat: no-repeat;
    `}
`;

const Content = styled.div`
    width: 100%;
    margin: 0 auto;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    ${media.desktop`
        height: 100%;
        width: 1024px;
    `}
`;

const Blend = styled.div`
    background: rgba(0,0,0, .5);
    position: absolute;
    top: 90px;
    left: 0;
    width: 100%;
    height: calc(100% - 90px);
`;

const ScrollBox = styled.div`
    height: calc(100% - 90px);
`;

interface IProps {
    children: JSX.Element;
}

export const Layout: FC<IProps> = props => {
    return (
        <Wrapper>
            {window.innerWidth > 1024 &&
                <Blend />
            }
            <TopMenu />
            <ScrollBox>
                {window.innerWidth > 1024 ? (
                    <Scrollbars autoHide>
                        <Content>
                            {props.children}
                        </Content>
                    </Scrollbars>
                ) : (
                    <Content>
                        {props.children}
                    </Content>
                )}
            </ScrollBox>
        </Wrapper>
    );
};