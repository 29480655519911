import { FC } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { colorStack } from '../../style/colorStack';
import profiles from '../../data/profiles.json';

const Wrapper = styled.div`
    background: rgba(0,0,0,.5);
    color: ${colorStack.white};
    padding: 1rem;
    margin: 1rem 0;
    P, ul {
        margin-bottom: 1rem;
    }
    li {
        padding-left: 2rem;
        margin-bottom: .5rem;
        a {
            color: ${colorStack.green};
        }
    }
`;

export const History: FC = () => {
    return (
        <Wrapper>
            <p><strong>FUBAR 13TH</strong> powstał 13 stycznia 2021 roku podczas jednego z wielu ognisk na <strong>ASG KRAKÓW ARENA</strong></p>
            <p>Grupa początkowo liczyła 4 osoby:</p>
            <ul>
                <li><Link to="/profile/wonski">Wonski</Link></li>
                <li><Link to="/profile/mungo">Mungo</Link></li>
                <li><Link to="/profile/piwek">Piwek</Link></li>
                <li><Link to="/profile/arturro">Arturro</Link></li>
            </ul>
            <p>Z biegiem czasu zaczęliśmy się rozrastać przez alpacze łiłanie i zarażaliśmy naszą postawą innych</p>
            <p>Do grupy zaczęli dołączać nie tylko ludzie z Krakowa i okolic. Obecnie nasi członkowie mieszkają również w Warszawie, na Pomorzu i na Polesiu co czyni nas nie tylko jedną z najbardziej licznych ekip airsoftowych w Polsce ale i jedną z nielicznych grup ogólnopolskich.</p>
            <p>Obecnie grupa liczy {profiles.length} wspaniałych alpak</p>
            <p>W skład zarządu wchodzą:</p>
            <ul>
                <li><Link to="/profile/wonski">Wonski</Link></li>
                <li><Link to="/profile/mungo">Mungo</Link></li>
                <li><Link to="/profile/piwek">Piwek</Link></li>
                <li><Link to="/profile/polej">Polej</Link></li>
                <li><Link to="/profile/heburt">Heburt</Link></li>
                <li><Link to="/profile/smoku">Smoku</Link></li>
                <li><Link to="/profile/mahaj">Mahaj</Link></li>
            </ul>
            <p>W nasze szeregi przyjmujemy ludzi nietuzinkowych, z dużym dystansem do siebie i do świata. Lubimy się postrzelać, pobawić, napić. Ponad wszystko cenimy dobrą zabawę, którą próbujemy zarażać innych graczy, promując airsoft gdzie tylko się da.</p>
        </Wrapper>
    );
};