import reset from 'styled-reset';
import { createGlobalStyle } from 'styled-components';

import { colorStack } from './colorStack';
import { media } from './breakpoints';

export const GlobalStyle = createGlobalStyle`
    @font-face {
    font-family: wargate;
    src: url(fonts/wargate.ttf);
    }
    ${reset}
    * {
        box-sizing: border-box;
    }
    html {
        font-size: 16px;
    }

    body {
        background: #060604;
        font-family: 'Roboto', sans-serif;
        line-height: 1.2;
        ${media.desktop`
            overflow: hidden;
            height: 100vh;
        `}
    }
    a, p {
        color: ${colorStack.white};
        font-size: 1rem;
        text-decoration: none;
    }
    strong {
        font-weight: 700;
    }
`;