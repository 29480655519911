import { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { colorStack } from '../../style/colorStack';
import profiles from '../../data/profiles.json';
import { media } from '../../style/breakpoints';

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;
    ${media.desktop`
        flex-direction: row;
    `}
`;

const SingleMate = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    padding: 1rem;
    ${media.desktop`
        width: 45%;
    `}
    img {
        width: 150px;
        margin-right: 1rem;
        border-radius: 100%;
    }
`;

const Description = styled.div`
`;

const Name = styled.div`
    font-size: 1.5rem;
    color: ${colorStack.white};
    text-transform: uppercase;
    font-weight: 700;
`;

const Position = styled.div`
    font-size: 1rem;
    margin: 1rem 0;
    color: ${colorStack.white};
`;

const Profile = styled(Link)`
    color: ${colorStack.alpaca};
`;

export const Team: FC = () => {
    return (
        <Wrapper>
            {profiles?.map(profile =>
                <SingleMate>
                    <img src={`${process.env.PUBLIC_URL}/img/profiles/${profile.id}/avatar.png`} alt={profile.name} />
                    <Description>
                        <Name>{profile.name}</Name>
                        <Position>Pozycja: {profile.position}</Position>
                        <Profile to={`/profile/${profile.id}`}>Zobacz pełny profil &#10148;</Profile>
                    </Description>
                </SingleMate>
            )}
        </Wrapper>
    );
};