import { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { media } from '../../style/breakpoints';
import { colorStack } from '../../style/colorStack';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    padding: .5rem;
    justify-content: space-between;
    width: 100%;
    height: 90px;
    ${media.desktop`
        width: 1024px;
        margin: 0 auto;
        a {
            transition: color .3s;
            text-transform: uppercase;
            &:hover {
                color: ${colorStack.green};
            }
        }
    `}
    img {
        width: 80px;
    }
`;

const Navbar = styled.div`
    position: relative;
    width: 32px;
    height: 26px;
    .checkbox {
        position: absolute;
        display: block;
        height: 26px;
        width: 26px;
        top: 0;
        left: 0;
        z-index: 5;
        opacity: 0;
        cursor: pointer;
    }
    .menu-items {
        position: absolute;
        top: -32px;
        display: flex;
        height: calc(100vh + 32px);
        width: calc(100vw - 60px);
        transform: translate(-210%);
        display: flex;
        flex-direction: column;
        transition: transform 0.5s ease-in-out;
        flex-direction: column;
        background: ${colorStack.white};
        padding: 1rem;
        text-align: center;
    }
    .hamburger-lines {
        display: block;
        height: 26px;
        width: 32px;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .hamburger-lines .line {
        display: block;
        height: 4px;
        width: 100%;
        border-radius: 10px;
        background: #fff;
    }
    .hamburger-lines .line3 {
        transform-origin: 0% 100%;
        transition: transform 0.4s ease-in-out;
    }
    .hamburger-lines .line2 {
        transition: transform 0.2s ease-in-out;
    }
    .hamburger-lines .line1 {
        transform-origin: 0% 0%;
        transition: transform 0.4s ease-in-out;
    }
    input[type="checkbox"]:checked ~ .menu-items {
        transform: translateX(-110%);
    }
    input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
        transform: rotate(45deg);
    }
    input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
        transform: scaleY(0);
    }
    input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
        transform: rotate(-45deg);
    }
    .menu-items a {
        color: ${colorStack.black};
        font-size: 1.4rem;
        margin-bottom: 1rem;
    }
`;

export const TopMenu: FC = () => {
    return (
        <Wrapper>
            {window.innerWidth > 1024 ? (
                <>
                    <Link to="team">Team</Link>
                    <Link to="events">Wydarzenia</Link>
                    <Link to="/">
                        <img src={`${process.env.PUBLIC_URL}/img/logo.png`} alt="Fubar Airsoft Team logo" />
                    </Link>
                    <Link to="historia">O nas</Link>
                    {/* <Link to="">Sklep</Link>
                    <Link to="rekrutacja">Rekrutacja</Link> */}
                    <Link to="kontakt">Kontakt</Link>
                </>
            ) : (
                <>
                    <Link to="/">
                        <img src={`${process.env.PUBLIC_URL}/img/logo.png`} alt="Fubar Airsoft Team logo" />
                    </Link>
                    <Navbar>
                        <input className="checkbox" type="checkbox" name="" id="" />
                        <div className="hamburger-lines">
                            <span className="line line1"></span>
                            <span className="line line2"></span>
                            <span className="line line3"></span>
                        </div>
                        <div className="menu-items">
                            <Link to="team">Team</Link>
                            <Link to="events">Wydarzenia</Link>
                            <Link to="historia">O nas</Link>
                            {/* <Link to="">Sklep</Link>
                            <Link to="rekrutacja">Rekrutacja</Link> */}
                            <Link to="kontakt">Kontakt</Link>
                        </div>
                    </Navbar>
                </>
            )}

        </Wrapper>
    );
};