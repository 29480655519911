import { FC } from 'react';
import styled from 'styled-components';

import { colorStack } from '../../style/colorStack';

const Wrapper = styled.div`
    display: flex;
    margin-top: 2rem;
    flex-direction: column;
    a {
        width: 100px;
    }
    img {
        width: 100%;
    }
`;

const Item = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    span {
        color: ${colorStack.white};
    }
`;

export const Contact: FC = () => {
    return (
        <Wrapper>
            <Item>
                <a href="https://www.facebook.com/Fubar13thKrakow">
                    <img src={`${process.env.PUBLIC_URL}/img/facebook-fubar-airsoft-team.png`} alt="Facebook Fubar Airsoft Team" />
                </a>
                <span>Fubar13thKrakow</span>
            </Item>
            <Item>
                <a href="https://www.instagram.com/fubar_13th_krakow_airsoft_team">
                    <img src={`${process.env.PUBLIC_URL}/img/instagram-fubar-airsoft-team.png`} alt="Instagram Fubar Airsoft Team" />
                </a>
                <span>fubar_13th_krakow_airsoft_team</span>
            </Item>
            <Item>
                <a href="mailto:fubar13thkrakow@gmail.com">
                    <img src={`${process.env.PUBLIC_URL}/img/email-fubar-airsoft-team.png`} alt="Email Fubar Airsoft Team" />
                </a>
                <span>fubar13thkrakow@gmail.com</span>
            </Item>
        </Wrapper>
    );
};