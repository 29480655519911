import { FC } from 'react';
import styled from 'styled-components';

import { colorStack } from '../../style/colorStack';
import events from '../../data/events.json';

const Wrapper = styled.div`
    display: flex;
    height: 100%;
    img {
        width: 100%;
    }
    h1 {
        font-size: 2rem;
        color: ${colorStack.white};
        margin: 1rem 0;
        text-transform: uppercase;
    }
    p {
        line-height: 1.8rem;
        font-size: 1rem;
    }
`;

const Events = styled.div`
    width: 300px;
    height: 100%;
    margin-right: 1rem;
    background: rgba(0,0,0, .5);
    color: ${colorStack.white};
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
`;

const EventsTitle = styled.span`
    font-size: 1.52rem;
    text-align: center;
    margin: 9px 0 1rem 0;
`;

const EventsResult = styled.div``;

const SingleEvent = styled.div`
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
        margin: 0 0 1rem 0;
    }
    img {
        margin: 0 0 1rem 0;
        max-width: 80%;
    }
`;

const Bar = styled.div`
    width: 90%;
    height: 1px;
    background: ${colorStack.white};
    margin-bottom: 1rem;
`;

export const Home: FC = () => {
    return (
        <Wrapper>
            {window.innerWidth > 1024 &&
                <Events>
                    <EventsTitle>
                        Wydarzenia:
                    </EventsTitle>
                    <EventsResult>
                        {events.filter(elem => new Date(elem.date).getTime() > new Date().getTime()).map(event => (
                            <SingleEvent>
                                <Bar />
                                <span>{event.eventName}</span>
                                <img src={event.logo} alt={event.eventName}/>
                                <span>Osób: {event.team.length}</span>
                                <span>Strona: {event.side}</span>
                            </SingleEvent>
                        ))}
                    </EventsResult>
                </Events>
            }
            <div>
                <h1>FUBAR 13TH Airsoft Team</h1>
                <p>
                    Przede wszystkim jesteśmy grupą przyjaciół. Dla nas ASG to nie tylko strzelanka. To sposób życia. <br />
                    Regularnie uczestniczymy w mniejszych i większych eventach. Istniejemy od 2021 roku.<br />
                    Chcemy promować dobre zachowania w środowisku ASG, a samo środowisko promować wśród społeczeństwa.
                </p>
            </div>
        </Wrapper>
    );
};