import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import profiles from '../../data/profiles.json';
import { media } from '../../style/breakpoints';
import { colorStack } from '../../style/colorStack';

const Wrapper = styled.div`
    margin-top: 2rem;
`;

const Content = styled.div`
    color: ${colorStack.white};
    display: flex;
    flex-direction: column;
    font-size: 1.3rem;
    ${media.desktop`
        font-size: 1rem;
    `}
    > span {
        margin-bottom: 1rem;
    }
    strong {
        display: block;
        color: ${colorStack.green};
        ${media.desktop`
            display: inline;
        `}
    }
`;

const TopContent = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 2rem;
    ${media.desktop`
        flex-direction: row;
    `}
    > img {
        width: 70%;
        max-width: 250px;
        border-radius: 100%;
        margin-bottom: 1rem;
        ${media.desktop`
            margin-bottom: 0;
        `}
    }
    span {
        font-size: 4rem;
        color: ${colorStack.white};
        font-weight: 500;
        font-family: wargate, 'Roboto', sans-serif;
        margin-left: 2rem;
    }
`;

const Guns = styled.div`
    color: ${colorStack.white};
    display: flex;
    background: rgba(0,0,0, .5);
    margin-top: 1rem;
    flex-direction: column;
    padding: 2rem 0 0 0;
    ${media.desktop`
        flex-direction: row;
        flex-wrap: wrap;
    `}
`;

const SingleGun = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    ${media.desktop`
        width: 50%;
    `}
    img {
        width: 50%;
        margin-top: 1rem;
        border-radius: 20px;
    }
    span {
        text-align: center;
    }
`;

const Replicas = styled.div`
    font-size: 2rem;
    margin-top: 4rem;
    color: ${colorStack.white};
`;

export const Profile: FC = () => {
    const { profileName } = useParams<{ profileName: string }>();
    const currentProfile = useMemo(() => profiles.filter(elem => elem.id === profileName)[0], [profileName]);

    return (
        <Wrapper>
            <TopContent>
                <img src={`${process.env.PUBLIC_URL}/img/profiles/${currentProfile.id}/avatar.png`} alt={currentProfile.name} />
                <span>{currentProfile.name.toUpperCase()}</span>
            </TopContent>
            <Content>
                <span><strong>Pozycja w grupie: </strong>{currentProfile.position}</span>
                <span><strong>Kim jest {currentProfile.name}: </strong>{currentProfile.description}</span>
            </Content>
            <Replicas>Repliki:</Replicas>
            <Guns>
                {currentProfile.guns.map((gun, index) => (
                    <SingleGun>
                        <span>{gun.name}</span>
                        <img src={`${process.env.PUBLIC_URL}/img/profiles/${currentProfile.id}/guns/${index + 1}.png`} alt={currentProfile.name} />
                    </SingleGun>
                ))}
            </Guns>
        </Wrapper>
    );
};