import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { Layout } from './components/common/layout';
import { Contact } from './components/routes/contact';
import { Events } from './components/routes/events';
import { History } from './components/routes/history';
import { Home } from './components/routes/home';
import { Profile } from './components/routes/profile';
import { Recruitment } from './components/routes/recruitment';
import { Statute } from './components/routes/statute';
import { Team } from './components/routes/team';
import { GlobalStyle } from './style/global';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <>
        <GlobalStyle />
        <BrowserRouter>
            <Layout>
                <Routes>
                    <Route index element={<Home />} />
                    <Route path="team" element={<Team />} />
                    <Route path="events" element={<Events />} />
                    <Route path="profile/:profileName" element={<Profile />} />
                    <Route path="historia" element={<History />} />
                    <Route path="regulamin" element={<Statute />} />
                    <Route path="rekrutacja" element={<Recruitment />} />
                    <Route path="kontakt" element={<Contact />} />
                </Routes>
            </Layout>
        </BrowserRouter>
    </>
);